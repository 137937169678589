import React from "react"
import { graphql } from "gatsby"
import PostsList from "../components/blog/postsList"

const Blog = props => {
  const articles = props.data.articles.nodes
  return <PostsList index articles={articles} />
}

export const query = graphql`
  query Blog {
    articles: allStrapiArticle(sort: { fields: date, order: DESC }) {
      nodes {
        author {
          name
          id
          avatar {
            childImageSharp {
              fixed(width: 40, height: 40, cropFocus: CENTER) {
                ...GatsbyImageSharpFixed
              }
            }
            childImageSharpSmaller: childImageSharp {
              fixed(width: 20, height: 20, cropFocus: CENTER) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        title
        slug
        blurb
        image {
          childImageSharp {
            fluid(maxWidth: 480, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        SEO {
          titleseo
          description
        }
        date(formatString: "MMM Do, YYYY")
        categories {
          name
        }
      }
    }
  }
`

export default Blog
