import React from "react"
import { Link } from "gatsby"
import { Row } from "react-grid-system"
import ArticleWrapper from "../layout/articleWrapper"
import SEO from "../seo"
import Layout from "../layout"
import BlogSidebar from "./blogSidebar"
import FullWidthSection from "../layout/fullWidthSection"
import InnerContentContainer from "../layout/innerContentContainer"
import Column from "../layout/column";
import NotFoundPage from "../notFoundPage"

import Post from "./post"
import TopPost from "./topPost"

const DEFAULT_BLOG_SEO_TITLE = "Construction Management Blog"
const DEFAULT_BLOG_SEO_DESCRIPTION =
  "Our Blog is where you'll find practical, actionable advice that can help you manage your construction business or remote workforce more effectively"

const PostsList = ({ title, articles, titleseo, descriptionseo, index }) => {
  if (articles && articles.length) {
    const topPost = articles[0]
    const restArticles = articles.slice(1)
    const pageTitle = index
      ? "SINC Workforce Management Blog"
      : `category / ${title}`
    const moreArticlesText = index ? "more articles" : `more in ${title}`
    const seoTitle = index ? DEFAULT_BLOG_SEO_TITLE : titleseo
    const seoDescription = index ? DEFAULT_BLOG_SEO_DESCRIPTION : descriptionseo
    const moreArticles = restArticles && restArticles.length ? true : false
    return (
      <Layout>
        <SEO title={seoTitle} description={seoDescription} />
        <FullWidthSection style={{ paddingBottom: 0, paddingTop: "3rem" }}>
          <InnerContentContainer>
            <div className={`blog-title-container ${!index && "left"}`}>
              <h2 className="blog-title">{pageTitle}</h2>
              {index && (
                <p>
                  In our blog we focus on practical info and tips to help you
                  manage your business better
                </p>
              )}
            </div>

            <TopPost id={topPost.title} article={topPost} />
            {moreArticles && (
              <div className="blog-title-container left mid-page">
                <h2 className="blog-title">{moreArticlesText}:</h2>
              </div>
            )}
          </InnerContentContainer>
        </FullWidthSection>

        <ArticleWrapper style={{ paddingTop: "0.5rem" }}>
          <Row style={{ width: "100%" }}>
            {moreArticles ? (
              restArticles.map(article => {
                return (
                  <Column sm={12} md={6} lg={4}>
                    <Post id={article.title} article={article} />
                  </Column>
                )
              })
            ) : (
              <Column style={{ marginTop: "2rem" }}>
                <div className="blog-title-container left">
                  <p>
                    There Are No More Articles For This Category.{" "}
                    <Link to={`/blog`}>Back To Blog Home</Link>
                  </p>
                </div>
              </Column>
            )}
          </Row>
          <BlogSidebar blogIndex />
        </ArticleWrapper>
      </Layout>
    )
  }
  return <NotFoundPage />
}

export default PostsList
