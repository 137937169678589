import React from "react"
import Image from "gatsby-image"
import Markdown from "react-markdown"
import { IoMdArrowRoundForward } from "react-icons/io"
import { Link } from "gatsby"

import Avatar from "./components/avatar"

const Post = ({ article }) => {
  const { image, title, blurb, slug, date, author } = article
  const fluid = image.childImageSharp.fluid
  const fixedAvatar = author.avatar ? author.avatar.childImageSharp.fixed : null
  return (
    <article className="post-wrapper">
      <div className="thumb-img-wrapper">
        <Link to={`/blog/posts/${slug}`} className="link">
          <Image style={{ maxHeight: 160 }} fluid={fluid} />
        </Link>
      </div>
      <Avatar name={author.name} date={date} fixedAvatar={fixedAvatar} />
      <div>
        <Link to={`/blog/posts/${slug}`} className="link">
          <span className="post-title">{title}</span>
        </Link>
        <div className="post-blurb-and-read-more">
          <Markdown children={blurb} />

          <div className="post-read-more-wrapper">
            <Link to={`/blog/posts/${slug}`} className="link">
              Continue Reading{" "}
              <IoMdArrowRoundForward style={{ position: "relative", top: 3 }} />
            </Link>
          </div>
        </div>
      </div>
    </article>
  )
}

export default Post
