import React from "react"
import Image from "gatsby-image"
import { Row } from "react-grid-system"
import Markdown from "react-markdown"
import { IoMdArrowRoundForward } from "react-icons/io"
import { Link } from "gatsby"

import Avatar from "./components/avatar"
import Column from "../layout/column"

const TopPost = ({ article }) => {
  const { categories, image, title, blurb, slug, date, author } = article
  const fluid = image.childImageSharp.fluid
  const fixedAvatar = author.avatar.childImageSharpSmaller.fixed
  return (
    <article className="post-wrapper">
      <Row style={{ width: "100%" }}>
        <Column sm={12} md={6} lg={7}>
          <Link to={`/blog/posts/${slug}`} className="link">
            <Image style={{ maxHeight: 400 }} fluid={fluid} className="img" />
          </Link>
        </Column>
        <Column sm={12} md={6} lg={5}>
          <Avatar
            name={author.name}
            date={date}
            fixedAvatar={fixedAvatar}
            topPost
          />
          <div className="lower-post-area top-post">
            <Link to={`/blog/posts/${slug}`} className="link">
              <span className="post-title top-post">{title}</span>
            </Link>
            <div className="post-blurb-and-read-more">
              <Markdown children={blurb} />
              <div className="post-categories-wrapper">
                {categories.map(category => {
                  let categorySlug = category.name.replace(/\s+/g, "-")
                  return (
                    <Link
                      to={`/blog/category/${categorySlug}`}
                      className="category"
                    >
                      <span className="category">{category.name}</span>
                    </Link>
                  )
                })}
              </div>
              <div className="post-read-more-wrapper">
                <Link to={`/blog/posts/${slug}`} className="link">
                  Continue Reading{" "}
                  <IoMdArrowRoundForward
                    style={{ position: "relative", top: 3 }}
                  />
                </Link>
              </div>
            </div>
          </div>
        </Column>
      </Row>
    </article>
  )
}

export default TopPost
